import Api from "@/services/Index";

const admin = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/minor/applications/admin/index", config);
};
const adminDelete = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("minor/applications/admin/delete/" + id);
};

const getAll = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/minor/applications");
};

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/minor/applications/" + id);
};

const programs = async (facultyCode) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/programs/" + facultyCode);
};

const programsAdmin = async (facultyCode, studentProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/programs/" + facultyCode + "/" + studentProgramId);
};

const store = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/minor/applications");
};

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/minor/applications/" + id);
};

const downloadAsExcel = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/minor/applications/excel-export", {
        ...config,
        responseType: "arraybuffer",
    });
};

const checkDate = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/check-date");
};

const studentForm = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/student-form");
};

const makeApplication = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/make-application");
};

const showApplication = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/show-application");
};

const minorApplicationsPrograms = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/programs/" + id);
};

const uploadDocument = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/upload/", data);
};

const deleteDocument = async (type) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/double/majors/student-delete-document/" + type);
};

const downloadDocument = async (type, responseType = 'arraybuffer') => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/double/majors/download/${type}`, { responseType });
};

const makePreference = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/make-preference", data);
};

const getPreferences = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/preferences", config);
};

const deletePreferences = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/double/majors/preferences/" + id);
};

const savePreference = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/preferences/save");
};

const changeRank = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/change-preference-ranks", data);
};

const approveDocument = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/approve-document", data);
};

const studentAffairsDownloadDocument = async (id, type, responseType = 'arraybuffer') => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/double/majors/download-document/${id}/${type}`, { responseType });
};

const studentAffairsApproveDocument = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(`/double/majors/approve-document`, data);
};

const studentAffairsIndex = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/student-affairs-index", config);
};

const approvePreference = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/approve-preference", data);
};

const approveApplication = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/approve-application", data);
};

const declineApplication = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/decline-application", data);
};

const doubleMajorShowGetId = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/show/" + id);
};

const studentAffairsChangePreferenceRanks = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/student-affairs-change-preference-ranks", data);
};

const studentAffairsDecline = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/decline-application", data);
};

const studentAffairsUpdateApplicationStatus = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "/double/majors/student-affairs-update-application-status",
        data
    );
};

const studentAffairsApprovePreference = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "/double/majors/approve-preference",
        data
    );
};

const studentAffairsUpdateSemesterCount = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "/double/majors/student-affairs-update-semester-count",
        data
    );
};

const studentAffairsApproveApplication = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "/double/majors/approve-application",
        data
    );
};

const prepSchoolIndex = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/prep-school-index", data);
};

const prepSchoolApprove = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/prep-school-approve", data);
};

const academicUnitOfficeIndex = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/academic-unit-office-index", data);
};

const academicUnitOfficeApprove = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/academic-unit-office-approve", data);
};

const studentAffairsAddPreference = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/student-affairs-add-preference", data);
};

const studentAffairsDeletePreference = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/student-affairs-delete-preference", data);
};

const studentAffairsSendMessage = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/student-affairs-send-message", data);
};

const studentAffairsRestorePreference = async (preferenceId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/student-affairs-restore-preference/" + preferenceId);
};

const prepSchoolBulkMessage = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/double/majors/prep-school-bulk-message", data);
};

const excelExport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/double/majors/excel-export", { ...config, responseType: "arraybuffer" });
};

export default {
    admin,
    adminDelete,
    studentForm,
    getAll,
    get,
    programs,
    store,
    del,
    downloadAsExcel,
    checkDate,
    makeApplication,
    showApplication,
    minorApplicationsPrograms,
    makePreference,
    getPreferences,
    deletePreferences,
    savePreference,
    changeRank,
    studentAffairsIndex,
    studentAffairsDecline,
    studentAffairsUpdateApplicationStatus,
    studentAffairsApprovePreference,
    studentAffairsApproveApplication,
    prepSchoolIndex,
    prepSchoolApprove,
    academicUnitOfficeIndex,
    academicUnitOfficeApprove,
    doubleMajorShowGetId,
    programsAdmin,
    studentAffairsAddPreference,
    studentAffairsDeletePreference,
    prepSchoolBulkMessage,
    uploadDocument,
    deleteDocument,
    downloadDocument,
    approveDocument,
    approvePreference,
    approveApplication,
    declineApplication,
    studentAffairsChangePreferenceRanks,
    studentAffairsDownloadDocument,
    studentAffairsApproveDocument,
    studentAffairsUpdateSemesterCount,
    studentAffairsSendMessage,
    studentAffairsRestorePreference,
    excelExport
};
